.search {
    &-item {
        display: block;
        color: var(--body-text-color);
        text-decoration: none;
        border: 1px solid;
        border-color: var(--user-dropdown-devider-border-color);
        padding: 0.5rem 1rem;
        &:hover {
            background: var(--user-dropdown-item-background-color-hover);
            color: var(--user-dropdown-item-color);
        }
        &-category {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &-tags {
            font-size: 75%;
        }
    }
}

.navigation-bar {
    --bs-gutter-x: 40px;
    &-link {
        color: var(--navigation-bar-link-color);
        text-decoration: none;
        transition: 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
        &:hover {
            color: var(--navigation-bar-link-color-hover);
            text-decoration: none;
            transition: 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
    }
    &-user {
        &-dropdown {
            &-icon {
                position: relative;
                z-index: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 35px;
                height: 35px;
                background: transparent;
                border-radius: 100%;
                color: var(--navigation-bar-user-dropdown-icon-color);
                text-decoration: none;
                overflow: hidden;
                &:hover {
                    background: var(--navigation-bar-user-dropdown-icon-background-color);
                    color: var(--navigation-bar-user-dropdown-icon-color-hover);
                }
            }
        }
    }
}

.user-menu {
    --bs-gutter-x: 15px;
    --bs-gutter-y: 5px;
    align-items: center;
    &-item {
        display: flex;
        align-items: center;
        width: 100%;
        background-color: transparent;
        border-radius: 10px;
        padding: 5px 10px;
        min-height: 40px;
        font-size: 0.8rem;
        color: var(--user-menu-item-color);
        line-height: 1.5;
        text-decoration: none;
        transition: 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
        &:hover {
            background-color: var(--user-menu-item-hover-background-color);
            color: var(--user-menu-item-hover-color);
            text-decoration: none;
            transition: 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
    }
    &__name,
    &__link {
        width: 100%;
        max-width: 110px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        --avatar-sm-width: 18px;
        --avatar-sm-height: 18px;
        width: var(--avatar-sm-width);
        height: var(--avatar-sm-height);
        & img,
        & image,
        & svg,
        & object,
        & iframe {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.list {
    &-item {
        --border-r-75: 0.75rem;
        display: flex;
        align-items: center;
        color: var(--user-menu-item-color);
        text-decoration: none;
        border-radius: var(--border-r-75);
        padding: 0.75rem 1rem;
        &:hover,
        &:focus,
        &:active,
        &.active {
            background-color: var(--user-menu-item-hover-background-color);
            color: var(--user-dropdown-item-color-hover);
        }
    }
    &-icon {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 18px;
        height: 18px;
        color: var(--user-menu-item-color);
        margin: auto;
        @media screen and (max-width: 325px) {
            width: 20px;
            height: 20px;
        }
    }
    &-name {
        font-weight: 400;
        font-style: normal;
        font-size: 65%;
        color: var(--user-menu-item-color);
        text-align: center;
        @media screen and (max-width: 374.98px) {
            font-size: 50%;
        }
    }
}

.sticky {
    &-left {
        &-block {
            & .user-menu {
                display: none;
            }
            @media (min-width: 1200px) {
                position: sticky;
                top: 60px;
                & .user-menu {
                    display: flex;
                }
            }
        }
    }
}

.footer-navigation-bar {
    --bs-gutter-x: 20px;
    &-link {
        font-size: 0.75rem;
        color: var(--navigation-bar-link-color);
        text-decoration: none;
        transition: 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
        &:hover {
            color: var(--navigation-bar-link-color-hover);
            text-decoration: none;
            transition: 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
    }
}

.navigation-share {
    --bs-gutter-x: 10px;
    --bs-gutter-y: 10px;
    &-link {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        color: $gray-400;
        border-radius: 4px;
        text-decoration: none;
        cursor: pointer;
        transition: 0.4s opacity cubic-bezier(0.215, 0.61, 0.355, 1),
            0.4s background cubic-bezier(0.215, 0.61, 0.355, 1);
        & svg {
            opacity: 0.5;
            transition: 0.2s opacity cubic-bezier(0.215, 0.61, 0.355, 1),
                0.2s background cubic-bezier(0.215, 0.61, 0.355, 1);
        }
        &:hover {
            background: $gray-200;
            color: $gray-900;
            text-decoration: none;
            transition: 0.4s opacity cubic-bezier(0.215, 0.61, 0.355, 1),
                0.4s background cubic-bezier(0.215, 0.61, 0.355, 1);
            & svg {
                opacity: 1;
                transition: 0.2s opacity cubic-bezier(0.215, 0.61, 0.355, 1),
                    0.2s background cubic-bezier(0.215, 0.61, 0.355, 1);
            }
        }
        &-icon {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            & svg,
            & img,
            & object,
            & image,
            & picture {
                width: 100%;
                height: 100%;
            }
        }
    }
}

.simple-navigation {
    & nav {
        --bs-gutter-x: 20px;
        --bs-gutter-y: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: calc(-1 * var(--bs-gutter-y));
        padding-right: calc(0.5 * var(--bs-gutter-x));
        padding-left: calc(0.5 * var(--bs-gutter-x));
        & > * {
            --border-r-card: 0.7rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            border-radius: var(--border-r-card);
            max-width: 50%;
            flex: 0 0 50%;
            margin-right: calc(var(--bs-gutter-x) * -0.5);
            margin-left: calc(var(--bs-gutter-x) * -0.5);
            margin-top: var(--bs-gutter-y);
        }
        & > a {
            background: var(--bs-blue) !important;
            border-color: var(--bs-blue) !important;
            color: var(--bs-white);
            cursor: pointer;
            text-decoration: none;
        }
        & span {
            background: var(--bs-gray-600) !important;
            border-color: var(--bs-gray-600) !important;
            opacity: 0.5;
            cursor: no-drop;
            text-decoration: none;
        }
    }
}

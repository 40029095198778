@import "../components/avatar";
@import "../components/flip-menu";
@import "../components/userblock";

.mobile {
    &-menu {
        position: fixed;
        bottom: 0;
        z-index: 1051;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 56px;
        @media (min-width: 1200px) {
            display: none;
        }
        &-custom {
            --header-custom-height: 40px;
            background-color: var(--header-background-color);
            backdrop-filter: saturate(170%) blur(8px);
        }
        &-inner {
            max-width: 576px;
            width: 100%;
        }
    }
    &-list {
        &-item {
            --border-r-75: 0.75rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: var(--mobile-list-item);
            text-decoration: none;
            border-radius: var(--border-r-75);
            &:hover,
            &:focus,
            &:active,
            &.active {
                background-color: var(--user-menu-item-hover-background-color);
                color: var(--user-dropdown-item-color-hover);
            }
        }
        &-icon {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            margin: auto;
            @media screen and (max-width: 325px) {
                width: 20px;
                height: 20px;
            }
        }
        &-name {
            font-weight: 700;
            font-style: normal;
            font-size: 65%;
            text-align: center;
            @media screen and (max-width: 374.98px) {
                font-size: 50%;
            }
        }
    }
}

.avatar {
    display: flex;
    overflow: hidden;
    &-xs {
        --avatar-xs-width: 18px;
        --avatar-xs-height: 18px;
        width: var(--avatar-xs-width);
        height: var(--avatar-xs-height);
    }
    &-sm {
        --avatar-sm-width: 30px;
        --avatar-sm-height: 30px;
        width: var(--avatar-sm-width);
        height: var(--avatar-sm-height);
    }
    &-md {
        --avatar-md-width: 48px;
        --avatar-md-height: 48px;
        width: var(--avatar-md-width);
        height: var(--avatar-md-height);
    }
    &-lg {
        --avatar-md-width: 64px;
        --avatar-md-height: 64px;
        width: var(--avatar-md-width);
        height: var(--avatar-md-height);
    }
    &-xl {
        --avatar-md-width: 128px;
        --avatar-md-height: 128px;
        width: var(--avatar-md-width);
        height: var(--avatar-md-height);
    }
    &-xxl {
        --avatar-md-width: 164px;
        --avatar-md-height: 164px;
        width: var(--avatar-md-width);
        height: var(--avatar-md-height);
    }
    &-zoom {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: var(--avatar-bg);
        opacity: 0;
        visibility: hidden;
        cursor: pointer;
        transition: 0.4s ease;
        &-icon {
            display: flex;
            width: 24px;
            height: 24px;
            color: var(--avatar-color);
            & img,
            & image,
            & svg,
            & object,
            & iframe {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    &:hover {
        & .avatar-zoom {
            opacity: 1;
            visibility: visible;
            transition: 0.4s ease;
        }
    }
    & img,
    & image,
    & svg,
    & object,
    & iframe {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.user-avatar {
    position: relative;
    background: var(--scrollbar-thumb-color);
    & img,
    & image,
    & picture {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &-status {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 1;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background: var(--bs-green);
        transform: translate(0, 0);
    }
}

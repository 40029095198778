$easing: ease-in-out;

.notifications {
    --width: 20rem;
    --height: 4.5rem;
    --gap: 1rem;

    position: fixed;
    bottom: 0;
    pointer-events: none;

    &:hover {
        .notification {
            // Unstack notifications when hovering over the container
            transform: translateY(0) scale(1);

            .notification-inner {
                opacity: 1;
                background-color: hsl(0 0% 100% / 40%);
            }

            &.exit-active {
                // When the list is expanded, avoid animating the y position
                transform: translateY(0) scale(0.5);

                .notification-inner {
                    background-color: hsl(100 0% 100% / 100%) !important;
                }
            }
        }
    }

    .notification {
        display: flex;
        transform: translateY(var(--y)) scale(var(--scale));
        transform-origin: center;
        transition: all var(--duration) $easing;
        pointer-events: auto;

        &.enter {
            transform: translateY(100%) scale(1);
            // Animate the first notification to slide in from the bottom
            margin-bottom: calc((var(--height) + var(--gap)) * -1);
        }

        &.enter-active {
            transform: translateY(var(--y)) scale(var(--scale));
            margin-bottom: 0;
        }

        &.exit-active {
            transform: translateY(calc(var(--y) - 10%)) scale(calc(var(--scale) - 0.1));
            margin-bottom: calc((var(--height) + var(--gap)) * -1);

            .notification-inner {
                opacity: 0;
            }
        }
    }

    .notification-inner {
        background-color: var(--bg);
        -webkit-backdrop-filter: blur(0.5rem);
        backdrop-filter: blur(0.5rem);
        padding: 0 1rem;
        border-radius: 0.5rem;
        width: var(--width);
        height: var(--height);
        margin-bottom: var(--gap);
        opacity: var(--opacity);
        transition: all var(--duration) $easing;
        display: flex;
        align-items: center;

        h2 {
            font-weight: bold;
            font-size: 0.9rem;
        }

        p {
            margin-top: 0.5rem;
            font-size: 0.8rem;
        }

        .close {
            background: none;
            border: none;
            position: absolute;
            right: 0;
            top: 0;
            font-size: 0.8rem;
            padding: 0.5rem;
            cursor: pointer;
            display: flex;
        }

        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 0.3rem;
            margin-right: 1rem;
            font-size: 1rem;
            color: white;

            &.error {
                background-color: #f87171;
            }

            &.success {
                background-color: #10b981;
            }

            &.info {
                background-color: #60a5fa;
            }

            &.warning {
                background-color: #f59e0b;
            }
        }
    }
}

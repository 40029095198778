img,
image,
picture,
svg,
iframe,
object {
    &.lazyload,
    &.lazyloading {
        -webkit-mask-image: -webkit-radial-gradient(white, black);
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        animation: skeleton-animation 3s infinite linear;
        background: var(--skeleton-background);
        background-repeat: repeat-x;
        border-color: transparent;
        opacity: 1;
        transition: 0.3s ease;
    }
    &.lazyloaded {
        opacity: 1;
        transition: 0.3s ease;
    }
}

@keyframes skeleton-animation {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: -100% 0;
    }
}

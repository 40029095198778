.w {
    &-xs {
        &-100 {
            @media screen and (max-width: 576px) {
                width: 100% !important;
            }
        }
    }
    &-sm {
        &-100 {
            @media screen and (max-width: 767px) {
                width: 100% !important;
            }
        }
    }
    &-md {
        &-100 {
            @media (min-width: 768px) {
                width: 100% !important;
            }
        }
    }
    &-lg {
        &-100 {
            @media (min-width: 992px) {
                width: 100% !important;
            }
        }
    }
    &-xl {
        &-100 {
            @media (min-width: 1200px) {
                width: 100% !important;
            }
        }
    }
}

.pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    --bs-gutter-x: 20px;
    --bs-gutter-y: 20px;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
    & > * {
        max-width: 100%;
        padding-right: calc(var(--bs-gutter-x) * 0.5);
        padding-left: calc(var(--bs-gutter-x) * 0.5);
        margin-top: var(--bs-gutter-y);
    }
    & .page-item {
        width: 50%;
        flex: 0 0 auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        &.disabled {
            opacity: 0.75;
            & .page-link {
                background: var(--card-background-color);
            }
        }
        &:first-child {
            & .page-link {
                border-top-left-radius: var(--border-r-pill);
                border-top-right-radius: var(--border-r-pill);
                border-bottom-left-radius: var(--border-r-pill);
                border-bottom-right-radius: var(--border-r-pill);
            }
        }
        &:last-child {
            & .page-link {
                border-top-left-radius: var(--border-r-pill);
                border-top-right-radius: var(--border-r-pill);
                border-bottom-left-radius: var(--border-r-pill);
                border-bottom-right-radius: var(--border-r-pill);
            }
        }
    }
    & .page-link {
        --border-r-pill: 50rem;
        width: 100%;
        background: var(--bs-primary);
        color: var(--bs-white);
        border-top-left-radius: var(--border-r-pill);
        border-top-right-radius: var(--border-r-pill);
        border-bottom-left-radius: var(--border-r-pill);
        border-bottom-right-radius: var(--border-r-pill);
        border: unset;
        text-align: center;
    }
}

.user {
    &-dropdown {
        &-menu {
            width: 250px;
            // margin-top: 25px !important;
        }
        &-item {
            display: flex;
            width: 100%;
            padding: 0.75rem 1.25rem;
            border-radius: var(--border-r-75);
            font-weight: 500;
            font-size: 0.75rem;
            color: var(--user-dropdown-item-color);
            text-decoration: none;
            transition: color 0.8s cubic-bezier(0.215, 0.61, 0.355, 1),
                background 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
            &:hover {
                background: var(--user-dropdown-item-background-color-hover);
                color: var(--user-dropdown-item-color-hover);
                text-decoration: none;
                transition: color 0.8s cubic-bezier(0.215, 0.61, 0.355, 1),
                    background 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
            }
        }
        &-container {
            --bs-gutter-x: 12px;
            width: 100%;
            padding-right: var(--bs-gutter-x, 0.75rem);
            padding-left: var(--bs-gutter-x, 0.75rem);
            margin-right: auto;
            margin-left: auto;
        }
        &-devider {
            border-top: 1px;
            border-style: solid;
            border-color: var(--user-dropdown-devider-border-color);
            opacity: 1;
        }
    }
}

.share {
    &-dropdown {
        &-menu {
            padding-top: 0;
            padding-bottom: 15px;
        }
        &-container {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}

.navigation {
    &-share {
        &-link {
            & svg {
                & path {
                    fill: var(--user-dropdown-item-color);
                }
            }
            &:hover {
                background: var(--list-group-item-hover-background);
                & svg {
                    & path {
                        fill: var(--user-menu-item-hover-color);
                    }
                }
            }
        }
    }
}

.dropdown {
    &-overlay {
        display: none;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 200vh;
        z-index: 0;
        opacity: 0.5;
        background: var(--modal-backdrop-color);
    }
    &-header {
        color: var(--user-dropdown-item-color);
    }
    &-menu {
        --bs-dropdown-border-radius: 0.75rem;
        --bs-dropdown-bg: var(--dropdown-menu-background-color);
        --bs-dropdown-color: var(--user-dropdown-item-color);

        // ios design from mobile
        --gutter-x: 1.5rem;
        @media screen and (max-width: 575.98px) {
            position: fixed !important;
            top: auto !important;
            left: 0 !important;
            right: 0 !important;
            bottom: 0 !important;
            --bs-dropdown-zindex: 1060;
            width: calc(100% - ((var(--gutter-x) * 0.5) * 2)) !important;
            transform: translate(calc(var(--gutter-x) * 0.5), -60px) !important;
        }
        &-title {
            background: var(--dropdown-menu-title-background-color);
            color: var(--user-dropdown-item-color);
            border-top-left-radius: var(--border-r-75);
            border-top-right-radius: var(--border-r-75);
            padding-top: 10px;
            padding-bottom: 10px;
            margin-bottom: 10px;
            font-weight: 600;
            overflow: hidden;
        }
        &.show {
            & + .dropdown-overlay {
                display: block !important;
            }
        }
    }
    &-item {
        font-size: 0.8rem;
        color: var(--user-dropdown-item-color);
        &:hover,
        &:focus {
            background-color: var(--user-dropdown-item-background-color-hover);
            color: var(--user-dropdown-item-color-hover);
        }
    }
    &-divider {
        border-top: 1px solid var(--user-dropdown-devider-border-color);
    }
}

.flip-menu {
    --border-r-75: 0.75rem;
    position: fixed;
    bottom: 56px;
    left: 50%;
    z-index: 1050;
    max-width: 516px;
    width: 100%;
    max-height: 70vh;
    height: max-content;
    background: var(--modal-usermenu-background);
    color: var(--body-text-color);
    padding: 15px 5px;
    border-top-left-radius: var(--border-r-75);
    border-top-right-radius: var(--border-r-75);
    overflow-x: hidden;
    overflow-y: auto;
    transform: translate(-50%, calc(100% + 56px * 2.5));
    transition-property: transform;
    transition: transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
    will-change: transform;
    @media (min-width: 1200px) {
        display: none;
    }
    &-inner {
        position: relative;
        z-index: 1;
    }
    &-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1045;
        width: 100vw;
        height: 100vh;
        background: var(--modal-backdrop-color);
        opacity: 0;
        visibility: hidden;
        transition: 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
        @media (min-width: 1200px) {
            display: none;
        }
        &.active {
            visibility: visible;
            opacity: 0.5;
        }
    }
    &.active {
        transform: translate(-50%, 0);
        transition: transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
}

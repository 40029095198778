.list-group {
    --border-r-card: 0.7rem;
    border-radius: var(--border-r-card);
}

.list-group-item {
    background-color: var(--list-group-item-background);
    border: 1px solid var(--list-group-item-border-color);
    color: var(--list-group-item-color);
    &:hover,
    &:focus {
        background-color: var(--list-group-item-hover-background);
        border: 1px solid var(--list-group-item-hover-border-color);
        color: var(--list-group-item-hover-color);
    }
}

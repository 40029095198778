.form {
    &-control {
        background-color: var(--form-control-background-color);
        border: 1px solid var(--form-control-border-color);
        font-size: 0.85rem;
        color: var(--form-control-color);
        &::placeholder {
            color: var(--form-control-placeholder-color);
        }
        &:focus {
            background-color: var(--form-control-background-focus-color);
            border: 1px solid var(--form-control-border-focus-color);
            color: var(--form-control-focus-color);
            box-shadow: 0 0 0 0.01rem rgb(13 110 253 / 25%);
        }
        &:disabled,
        &[readonly] {
            background-color: var(--form-control-readonly-backgroud);
            border: 1px solid var(--form-control-readonly-border);
            color: var(--form-control-readonly-color);
        }
        &-editor {
            position: relative;
            display: block;
            padding-top: 50px;
            & .editor-btns {
                position: absolute;
                top: 0;
                left: 0;
                flex-wrap: nowrap !important;
                padding: 5px;
                // background: var(--form-editor-container-bg-hex);
                border-bottom: 1px solid var(--form-control-readonly-backgroud);
                overflow-x: auto;
                &::-webkit-scrollbar {
                    width: 1px;
                    height: 1px;
                    border-radius: 0;
                }

                ::-webkit-scrollbar-track {
                    background-color: transparent;
                    border-radius: 0;
                }

                ::-webkit-scrollbar-thumb {
                    background-color: var(--scrollbar-thumb-color);
                    border-radius: 30px;
                    cursor: grabbing;
                }
            }
        }
        &-search {
            @media (min-width: 576px) {
                padding-right: 40px;
            }
        }
        &-cross {
            position: absolute;
            top: 50%;
            z-index: 2;
            display: block;
            width: 40px;
            height: 100%;
            padding-top: 5px;
            background: initial;
            text-align: center;
            cursor: pointer;
            transform: translate(0, -50%);
            &-left {
                left: 0;
            }
            &-right {
                right: 0;
            }
        }
    }
    &-image {
        & input[type="file"] {
            display: none;
        }
        &-label {
            --border-r-3: 0.3rem;
            display: block;
            background-color: var(--form-control-background-color);
            border: 1px solid var(--form-control-border-color);
            border-radius: var(--border-r-3);
            color: var(--form-control-color);
            padding: 6px 12px;
            cursor: pointer;
        }
        &-text {
            position: relative;
            display: flex;
            justify-content: center;
            font-size: 80%;
            color: var(--body-text-color);
            opacity: 0.5;
            & .file-delete {
                position: absolute;
                top: 50%;
                right: 0;
                width: 20px;
                height: 20px;
                background: var(--btn-close-background-image);
                transform: translate(0, -50%);
            }
        }
    }
    &-group {
        position: relative;
        z-index: 1;
        &-search {
            max-width: 100%;
            @media (min-width: 1200px) {
                max-width: 640px;
            }
        }
    }
}

.file {
    &-hide {
        &-btn {
            &::file-selector-button {
                display: none;
            }
        }
    }
}

.image {
    &-label {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        max-width: 72px;
        max-height: 72px;
        width: 100%;
        height: 100%;
        margin: auto;
        & svg {
            max-width: 260px;
            max-height: 260px;
            width: 100%;
            height: 100%;
            opacity: 0.5;
        }
    }
}

.edit {
    &-attach {
        position: relative;
        z-index: 1;
        border-radius: 4px;
        overflow: hidden;
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            background: var(--form-editor-container-bg);
            opacity: 0.5;
        }
        &-image {
            object-fit: contain;
        }
    }
}

.popover {
    background: var(--dropdown-menu-title-background-color);
    &-header {
        color: var(--body-text-color);
        background: var(--dropdown-menu-background-color);
        border-bottom-color: var(--user-dropdown-devider-border-color);
    }
    &-body {
        color: var(--body-text-color);
        background: var(--dropdown-menu-title-background-color);
        padding: 0.5rem 1rem;
    }
    &[data-popper-placement^="top"] {
        & .popover-arrow {
            &::after {
                border-top-color: var(--dropdown-menu-title-background-color);
            }
        }
    }
    &[data-popper-placement^="bottom"] {
        & .popover-arrow {
            &::after {
                border-bottom-color: var(--dropdown-menu-title-background-color);
            }
        }
    }
    &[data-popper-placement^="right"] {
        & .popover-arrow {
            &::after {
                border-right-color: var(--dropdown-menu-title-background-color);
            }
        }
    }
    &[data-popper-placement^="left"] {
        & .popover-arrow {
            &::after {
                border-left-color: var(--dropdown-menu-title-background-color);
            }
        }
    }
    &-arrow {
        display: none !important;
    }
}

@import "../components/avatar";
@import "../components/userblock";
.header-custom {
    // --bs-bg-opacity: 0.25;
    --header-custom-height: 40px;
    background-color: var(--header-background-color);
    backdrop-filter: saturate(170%) blur(8px);
}

.user-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    & .avatar {
        margin-right: 15px;
    }
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 56px;
    transition: transform 0.2s ease;
    &-inner {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    &-logo {
        display: block;
        width: 100%;
        height: 1.45rem;
        &-new {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 1.75rem;
            @media (min-width: 576px) {
                height: 2rem;
            }
        }
        & svg,
        & img {
            width: auto;
            height: 100%;
            object-fit: cover;
        }
    }
    &-navigation {
        display: none;
        @media (min-width: 1200px) {
            display: flex;
        }
    }
    &-burger,
    &-search {
        display: flex;
        margin-left: auto;
        @media (min-width: 1200px) {
            display: none;
        }
        &-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 45px;
            height: 35px;
            background-color: var(--header-burger-btn-background);
            border-radius: 8px;
            border: 0;
            box-shadow: unset;
            & svg {
                width: 24px;
                height: 24px;
                stroke: var(--header-burger-btn-svg);
            }
        }
    }
    &-auth {
        display: none;
        margin-left: auto;
        @media (min-width: 1200px) {
            display: flex;
        }
    }
    &-up {
        transform: translateY(-100%);
        @keyframes sticky-header-up {
            from {
                opacity: 1;
                transform: translateY(0);
            }
            to {
                opacity: 0;
                transform: translateY(-100%);
            }
        }
    }
}

@charset 'UTF-8';

// vendor
@import "./vendor/animate.lib.scss";
@import "./vendor/bootstrap.lib.scss";

// utilities
@import "./utilities/sizes";

// primary
@import "./components/cursor";
@import "./components/btn";
@import "./components/form";

// layouts
@import "./layouts/header";
@import "./layouts/footer";
@import "./layouts/mobile-menu";
@import "./layouts/nav";

// components
@import "./components/animation";
@import "./components/border";
@import "./components/badge";
@import "./components/alert";
@import "./components/card";
@import "./components/dropdown";
@import "./components/lazyload";
@import "./components/list";
@import "./components/modal";
@import "./components/notify";
@import "./components/pagination";
@import "./components/popover";

// parts
@import "./parts/search-item";

// constructor
@import "./components/blockquote";
@import "./components/code";

::-webkit-scrollbar {
    width: 6px;
    height: 5px;
    border-radius: 0;
}

::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 0;
}

::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb-color);
    border-radius: 30px;
    cursor: grabbing;
}

html {
    background-color: var(--html-color);
}

body {
    background-color: var(--body-background-color);
    color: var(--body-text-color);
}

.container {
    @media (min-width: 1400px) {
        max-width: 1140px;
    }
}

.container-content {
    --content-pt: 56px;
    padding-top: var(--content-pt);
    padding-bottom: var(--content-pt);
    @media (min-width: 1200px) {
        padding-bottom: unset;
    }
}

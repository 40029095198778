.modal {
    will-change: transform;
    &:not(.show) {
        transform: scale(3);
        transition: 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    &.show {
        transform: scale(1);
        transition: 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    &.fade {
        transition: 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
        & .modal-dialog {
            transform: none;
        }
    }
    &-backdrop {
        background-color: var(--modal-backdrop-color);
    }
    &-content {
        background-color: var(--modal-background-color);
    }
    &-header {
        height: 56px;
        padding-left: 0;
        padding-right: 0;
        color: var(--modal-header-color);
        border-bottom: 1px solid var(--dropdown-menu-background-color);
        &__inner {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }
    &-body {
        padding-left: 0;
        padding-right: 0;
        &-usermenu {
            background-color: var(--modal-usermenu-background);
        }
    }
    &-usermenu {
        background-color: var(--modal-usermenu-background);
    }
    &-footer {
        padding-left: 0;
        padding-right: 0;
        border-top: 1px solid var(--dropdown-menu-background-color);
        & > * {
            margin: 0 auto;
        }
        &__btns {
            --bs-gutter-x: 20px;
        }
    }
    // types of modal's
    &-edit {
        & .container {
            max-width: 660px;
        }
    }
    &-search {
        & .container {
            max-width: 660px;
        }
    }
    &-photo {
        & .container {
            padding: 0;
            max-width: calc(100% - var(--bs-gutter-x));
            @media (min-width: 1200px) {
                max-width: calc(1140px - var(--bs-gutter-x));
            }
            @media (min-width: 1400px) {
                max-width: calc(1140px - var(--bs-gutter-x));
            }
        }
        &-wrap {
            position: relative;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            background: var(--form-editor-container-bg);
            border-radius: 0.75rem;
            overflow: hidden;
        }
        &-image {
            z-index: 2;
            object-fit: contain;
            max-width: 100%;
            max-height: 100%;
        }
        &-pseudo {
            position: relative;
            z-index: 1;
            width: 100%;
            height: 100%;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }
        &-cover {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            background-color: var(--form-editor-container-bg);
            background-size: cover;
            background-position: center;
            opacity: 0.25;
            margin: auto;
            overflow: hidden;
            filter: blur(20px);
            transform: translate3d(0, 0, 0);
            -webkit-mask-image: -webkit-radial-gradient(white, black);
            -webkit-backface-visibility: hidden;
            -moz-backface-visibility: hidden;
        }
    }
    &-square {
        border-radius: unset;
        & .modal-content {
            border-radius: unset !important;
        }
    }
}

.alert {
    &-secodary {
        color: var(--alert-secondary-color);
        background-color: var(--alert-secondary-background-color);
        border-color: var(--alert-secondary-border-color);
    }
    &-danger {
        &-dark {
            color: var(--alert-danger-dark-color);
            background-color: var(--alert-danger-dark-background-color);
            border-color: var(--alert-danger-dark-border-color);
        }
    }
    &-grey {
        background: var(--form-editor-container-bg);
        border-radius: 4px;
        padding: 10px;
    }
}

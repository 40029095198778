.blockquote,
blockquote {
    margin: unset;
    padding: 0 1rem;
    line-height: 3;
    background-color: var(--blockquotes-background-color);
    border: 0;
    border-left: 5px;
    border-style: solid;
    border-color: var(--blockquotes-border-color);
    & p {
        font-size: 1rem;
        color: var(--blockquotes-p-color);
    }
    &-footer {
        margin-top: 0;
        font-size: var(--bs-body-font-size);
        color: var(--blockquotes-footer-color);
    }
}

.footer {
    top: 60px;
    @media screen and (max-width: 991px) {
        border-top: 1px solid;
        border-color: var(--footer-border-color);
        padding-top: 20px;
    }
    @media (min-width: 1200px) {
        margin-top: 20px;
    }
}

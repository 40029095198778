.card {
    --border-r-card: 0.7rem;
    background-color: var(--card-background-color);
    color: var(--card-text-color);
    border-radius: var(--border-r-card);
    border: 1px solid var(--card-border-color);
    box-shadow: var(--card-box-shadow-color);
    &-header,
    &-header:first-child {
        border-top-left-radius: var(--border-r-card);
        border-top-right-radius: var(--border-r-card);
        border-bottom: 1px solid var(--card-border-color);
    }
    &-title {
        color: var(--card-title-color);
    }
    &-footer,
    &-footer:last-child {
        border-bottom-left-radius: var(--border-r-card);
        border-bottom-right-radius: var(--border-r-card);
        border-top: 1px solid var(--card-border-color);
    }
    &-post {
        @media screen and (max-width: 370px) {
            zoom: 85%;
        }
    }
}

.btn {
    --border-r-card: 0.7rem;
    font-size: 0.8rem;
    border-radius: var(--border-r-card);
    box-shadow: unset;
    &:focus {
        box-shadow: unset;
    }
    &:active:focus {
        box-shadow: unset;
    }
    &-like {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 0.75rem;
        line-height: 80%;
        background-color: rgba(var(--btn-text-bg-rgb), 0.6);
        color: rgba(var(--btn-text-color-rgb), 0.8);
        border-color: transparent;
        &:hover {
            background-color: transparentize($red-300, 0.8);
            color: $red-300;
        }
        &:focus,
        &.focus {
            background-color: transparentize($red-600, 0.6);
            color: $red-600;
            border-color: transparent;
        }
        &:focus:hover {
            background-color: transparentize($red-600, 0.6);
            color: $red-600;
        }
        &:focus:active {
            background-color: transparentize($red-600, 0.6);
            color: $red-600;
            border-color: transparent;
        }
        &:active {
            background-color: transparentize($red-600, 0.6) !important;
            color: $red-600 !important;
            border-color: transparent !important;
            opacity: 0.85;
        }
        &.liked {
            background-color: transparentize($red-300, 0.8);
            color: $red-300;
            & .btn-like__icon {
                & svg {
                    & path {
                        fill: currentColor;
                    }
                }
            }
        }
        &__icon {
            width: 18px;
            height: 18px;
            & img,
            & image,
            & picture,
            & object,
            & svg {
                width: 100%;
                height: 100%;
            }
        }
        &__value {
            margin-left: 0.75rem;
        }
    }
    &-repost {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 0.75rem;
        line-height: 80%;
        background-color: rgba(var(--btn-text-bg-rgb), 0.6);
        color: rgba(var(--btn-text-color-rgb), 0.8);
        border-color: transparent;
        &:hover {
            background-color: transparentize($teal-600, 0.8);
            color: $teal-600;
        }
        &:focus,
        &.focus {
            background-color: transparentize($teal-600, 0.8);
            color: $teal-600;
            border-color: transparent;
        }
        &:focus:hover {
            background-color: transparentize($teal-600, 0.8);
            color: $teal-600;
        }
        &:focus:active {
            background-color: transparentize($teal-600, 0.8);
            color: $teal-600;
            border-color: transparent;
        }
        &:active {
            background-color: transparentize($teal-600, 0.8) !important;
            color: $teal-600 !important;
            border-color: transparent !important;
            opacity: 0.85;
        }
        &__icon {
            width: 18px;
            height: 18px;
            & img,
            & image,
            & picture,
            & object,
            & svg {
                width: 100%;
                height: 100%;
            }
        }
        &__value {
            margin-left: 0.75rem;
        }
    }
    &-comments {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 0.75rem;
        line-height: 80%;
        background-color: rgba(var(--btn-text-bg-rgb), 0.6);
        color: rgba(var(--btn-text-color-rgb), 0.8);
        border-color: transparent;
        &:hover {
            background-color: transparentize($cyan-600, 0.8);
            color: $cyan-600;
        }
        &:focus,
        &.focus {
            background-color: transparentize($cyan-600, 0.8);
            color: $cyan-600;
            border-color: transparent;
        }
        &:focus:hover {
            background-color: transparentize($cyan-600, 0.8);
            color: $cyan-600;
        }
        &:focus:active {
            background-color: transparentize($cyan-600, 0.8);
            color: $cyan-600;
            border-color: transparent;
        }
        &:active {
            background-color: transparentize($cyan-600, 0.8) !important;
            color: $cyan-600 !important;
            border-color: transparent !important;
            opacity: 0.85;
        }
        &__icon {
            width: 18px;
            height: 18px;
            & img,
            & image,
            & picture,
            & object,
            & svg {
                width: 100%;
                height: 100%;
            }
        }
        &__value {
            margin-left: 0.75rem;
        }
    }
    &-views {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 0.75rem;
        line-height: 80%;
        background-color: rgba(var(--btn-text-bg-rgb), 0.6);
        color: rgba(var(--btn-text-color-rgb), 0.8);
        border-color: transparent;
        &:hover {
            background-color: transparentize($gray-600, 0.8);
            color: $gray-600;
        }
        &:focus,
        &.focus {
            background-color: transparentize($gray-600, 0.8);
            color: $gray-600;
            border-color: transparent;
        }
        &:focus:hover {
            background-color: transparentize($gray-600, 0.8);
            color: $gray-600;
        }
        &:focus:active {
            background-color: transparentize($gray-600, 0.8);
            color: $gray-600;
            border-color: transparent;
        }
        &:active {
            background-color: transparentize($gray-600, 0.8) !important;
            color: $gray-600 !important;
            border-color: transparent !important;
            opacity: 0.85;
        }
        &__icon {
            width: 18px;
            height: 18px;
            & img,
            & image,
            & picture,
            & object,
            & svg {
                width: 100%;
                height: 100%;
            }
        }
        &__value {
            margin-left: 0.75rem;
        }
    }
    &-close {
        background: var(--btn-close-background-image);
    }
    &-editor {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: var(--dropdown-menu-background-color);
        font-size: 0.85rem;
        line-height: 80%;
        color: var(--btn-text-color);
        border-radius: 5px;
        &:hover {
            background-color: var(--user-dropdown-item-background-color-hover);
            color: var(--btn-text-color);
        }
    }
    &-secondary {
        background-color: var(--btn-secondary-background);
        border-color: var(--btn-secondary-background);
        color: var(--body-text-color);
        &:hover,
        &:active {
            background-color: var(--btn-secondary-background-hover);
            border-color: var(--btn-secondary-background-hover);
            color: var(--body-text-color);
        }
        &:focus,
        &:active {
            background-color: var(--btn-secondary-background-hover);
            border-color: var(--btn-secondary-background-hover);
            color: var(--body-text-color);
            box-shadow: unset;
            filter: brightness(0.95);
        }
        &:active:focus {
            background-color: var(--btn-secondary-background-hover);
            border-color: var(--btn-secondary-background-hover);
            box-shadow: unset;
        }
    }
    &-gradient {
        position: relative;
        z-index: 1;
        color: var(--white);
        background: transparent;
        border-color: transparent;
        overflow: hidden;
        &-icon {
            transform: rotate(0deg);
            transition: transform 0.4s cubic-bezier(0.47, 0, 0.745, 0.715);
        }
        &:hover {
            & .btn-gradient-icon {
                transform: rotate(180deg);
                transition: transform 0.4s cubic-bezier(0.47, 0, 0.745, 0.715);
            }
        }
        &-place {
            --size: 180px;
            --speed: 5s;
            --easing: cubic-bezier(0.8, 0.2, 0.2, 0.8);
            position: absolute;
            top: -50%;
            left: 0;
            z-index: -1;
            transform: translate(-50%, -50%);
            width: var(--size);
            height: var(--size);
            filter: blur(calc(var(--size) / 8));
            background: linear-gradient(#9055ff, #13e2da);
            animation: rotate var(--speed) var(--easing) alternate infinite;
            border-radius: 50rem;
        }
    }
}

.code,
code,
pre {
    display: block;
    margin: unset;
    padding: 0rem 1rem;
    background: var(--code-background-color) !important;
    border: 0;
    border-left: 5px;
    border-style: solid;
    border-color: var(--code-border-color);
    & p {
        font-size: 1rem;
        color: var(--code-p-color);
    }
    &-footer {
        margin-top: 0;
        font-size: var(--bs-body-font-size);
        color: var(--code-footer-color);
    }
}
